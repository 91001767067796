.forgotOTPInput fieldset {
  border: none !important;
  border-radius: 0;
  border-bottom: 2px solid #d0d0d0 !important;
}

.forgotOTPInput input {
  width: 100% !important;
  background: none !important;
  color: #868686;
  font-size: 20px;
}

.UloginBox2 {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Coporateverification {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg");
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat;
  background-color: #F6FAFF;
  height: 100vh;
}

.BoxInfo {
  width: 100 !important;
  text-align: center;
  margin-top: 10%;
}

.BoxInfo h4 {
  width: 100 !important;
  color: #2B2727;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 3%;
}

.BoxInfo h6 {
  width: 100 !important;
  color: #606778;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 820px) {
  .Coporateverification {
    display: none;
  }
}/*# sourceMappingURL=UserRegisterEmail.css.map */