.forgotpasswordMain {
  display: flex;
  width: 100%;
}

.forgotpasswordStart {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.forgotpasswordBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.USerLOginBox2 {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.forgotpassword {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background: linear-gradient(90deg, #2680BD 7.58%, rgba(56, 184, 231, 0.794) 50.49%, rgba(81, 174, 245, 0.56) 92.52%);
  mix-blend-mode: normal;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 8%;
  padding-bottom: 8%;
  height: 100vh;
}

.forgot-form {
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 4%;
}

.forgot-form h3 {
  color: #000;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 4%;
}

.forgot-form h5 {
  color: #302D2D;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4%;
}

.forgotpasswordBox input {
  padding: 3% 0;
  border-radius: 6px;
  border: 1px solid #CAD0DB;
  text-indent: 10px;
  margin: 5% 0% 3%;
}

.forgotpasswordBox button {
  border-radius: 10px;
  background: #16469D;
  text-transform: capitalize;
  margin-bottom: 5%;
}

.forgotpasswordBox input::-moz-placeholder {
  color: #7B8499;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.forgotpasswordBox input::placeholder {
  color: #7B8499;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.forgot-form input:focus {
  border: 1.5px solid #000 !important;
}

.forgetBack {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
}

.forgetBack h6 {
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.backItem {
  width: 20px;
  height: 20px;
  color: #000;
}

.ForgotLOginBox2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg");
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat;
  background-color: #F6FAFF;
  height: 100vh;
}

.ForgotBoxSTart2 {
  width: 50%;
}

.ForgotBoxSTart2 .ForgiotMEdiaBox2 {
  width: 100% !important;
}

.ForgiotMEdiaBox2 img {
  border-radius: 2%;
  width: 100%;
  -o-object-fit: fill !important;
     object-fit: fill !important;
}

.ForgotBoxSTart2 ul .slick-active {
  width: 40px !important;
}

.ForgotBoxSTart2 ul .slick-active button {
  width: 40px !important;
}

.ForgotBoxSTart2 .slick-dots li button:before {
  width: 15px !important;
  content: "";
  background-color: gray;
  height: 5px;
  border-radius: 6px;
}

.ForgotBoxSTart2 ul .slick-active button::before {
  width: 40px !important;
  content: "";
  background-color: black !important;
  height: 5px;
}

.ForgotttBOxInfo {
  width: 100;
  text-align: center;
  margin-top: 10%;
}

.ForgotttBOxInfo h4 {
  width: 100 !important;
  color: #2B2727;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ForgotttBOxInfo h6 {
  width: 100 !important;
  color: #606778;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1024px) {
  .forgot-form {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .ForgotttBOxInfo h4 {
    font-size: 22px;
  }
  .ForgotttBOxInfo h6 {
    font-size: 17px;
  }
}
@media (max-width: 620px) {
  .ForgotLOginBox2 {
    display: none;
  }
  .forgotpasswordBox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}
@media (max-width: 415px) {
  .ForgotLOginBox2 {
    display: none;
  }
  .forgotpasswordBox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .forgot-form {
    padding: 0px;
  }
  .forgot-form h5 {
    margin: 0% 0;
    margin-bottom: 2%;
    text-align: left;
  }
  .forgetBack {
    margin: 0px;
  }
  .forgot-form h3 {
    display: flex;
    text-align: left;
  }
  .forgotpasswordBox button {
    margin: 8% 0;
  }
}/*# sourceMappingURL=ForgotPassword.css.map */