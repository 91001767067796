.ourProds {
  width: 100%;
  display: flex;
  padding: 40px 0;
  justify-content: center;
}

.ourProds > div {
  display: flex;
  width: 90%;
  flex-direction: column;
  gap: 20px;
}

.CardmainPro {
  display: flex;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.CardmainPro > a {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 270px;
  width: 100%;
  max-width: calc(25% - 20px);
}

.ourProds > div {
  display: flex;
  width: 90%;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 768px) {
  .CardmainPro > a {
    max-width: 100%;
  }
}/*# sourceMappingURL=ProductsLandingPage.css.map */