input:focus {
  outline: none !important;
  border: none !important;
}

textarea:focus {
  outline: none !important;
  border: none !important;
}

.RegisterMAin {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.RegisterStart {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.registerBoxes1 {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.registerBoxes2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg");
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat;
  background-color: #F6FAFF;
  height: 100vh;
}

.RegiaterBoxStart2 {
  width: 50%;
}

.RegiaterBoxStart2 .RegiaterMediaBox2 {
  width: 100% !important;
}

.RegiaterMediaBox2 img {
  border-radius: 2%;
  width: 100%;
  -o-object-fit: fill !important;
     object-fit: fill !important;
}

.resiterbox1 h1 {
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  padding: 0 !important;
  padding-bottom: 1% !important;
}

.FullName input {
  height: 36px !important;
}

.resiterbox1 h2 {
  text-align: start !important;
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 !important;
}

.register-heading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resiterbox1 {
  width: 75%;
  text-align: start;
}

.register-details label {
  text-align: start;
  color: #606778;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.fieldText {
  width: 85%;
}

.register-details input {
  height: 36px;
  border-radius: 6px;
  border: 1px solid #CAD0DB;
  text-indent: 12px;
}

.register-details input:focus {
  border: 1.5px solid #000 !important;
}

.myInput1:focus {
  border: 1.5px solid #000 !important;
}

.register-form h6 {
  color: #7B8499;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-align: start;
}

.register-form h4 {
  color: #7B8499;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-align: start;
  padding-top: 6%;
}

.register-form h4 .termC {
  color: #16469D;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-decoration-line: underline;
}

.register-form button {
  width: 35%;
  padding: 2.5% 0;
  font-size: 14px;
  line-height: 21px;
  border-radius: 10px;
  font-weight: 400;
  background-color: #16469d;
  text-transform: none;
  margin-top: 6%;
}

.FullName input {
  font-size: 14px;
  font-weight: 500;
}

.UloginFormButton button {
  width: 100% !important;
  color: #fff;
  border-radius: 0 6px 6px 0 !important;
  border: 2px solid #16469d !important;
}

.UloginFormButton button:hover {
  background-color: #1976d2 !important;
  border: 2px solid #1976d2 !important;
  color: #fff;
}

.register-form .termC {
  color: #12141A;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 145.455% */
  text-decoration-line: underline;
}

.RegisterBoxInfo {
  width: 100 !important;
  text-align: center;
  margin-top: 10%;
}

.RegisterBoxInfo h4 {
  width: 100 !important;
  color: #2B2727;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.RegisterBoxInfo h6 {
  width: 100 !important;
  color: #606778;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.register-form {
  width: 508px;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #ffffff;
  border-radius: 4px;
  transition: 0.8s ease-in-out;
}

.register-details {
  padding: 3% 12% 5% 12% !important;
  display: flex;
  gap: 20px;
  flex-direction: column;
  text-align: start;
}

.success-gif {
  width: 75%;
  align-self: center;
}

.RegiaterBoxStart2 ul .slick-active {
  width: 40px !important;
}

.RegiaterBoxStart2 ul .slick-active button {
  width: 40px !important;
}

.RegiaterBoxStart2 .slick-dots li button:before {
  width: 15px !important;
  content: "";
  background-color: gray;
  height: 5px;
  border-radius: 6px;
}

.RegiaterBoxStart2 ul .slick-active button::before {
  width: 40px !important;
  content: "";
  background-color: black !important;
  height: 5px;
}

@media (max-width: 912px) {
  .RegisterBoxInfo h4 {
    font-size: 20px;
  }
  .RegisterBoxInfo h6 {
    font-size: 18px;
  }
}
@media (max-width: 540px) {
  .registerBoxes2 {
    display: none;
  }
  .registerBoxes1 {
    width: 100%;
  }
}
@media (max-width: 415px) {
  .resiterbox1 h1 {
    font-size: 20px;
  }
  .resiterbox1 h2 {
    font-size: 12px;
  }
  .register-form h6 {
    font-size: 10px;
  }
  .register-form h4 {
    font-size: 12px;
    padding-top: 6%;
  }
  .register-form h4 .termC {
    font-size: 12px;
  }
  .register-form button {
    width: 35%;
    padding: 2.5% 0;
    font-size: 12px;
    margin-top: 6%;
  }
  .register-form .termC {
    font-size: 10px;
  }
  .register-details input {
    padding: 4% 0;
    border-radius: 6px;
    border: 1px solid #CAD0DB;
    text-indent: 12px;
  }
}/*# sourceMappingURL=Register.css.map */