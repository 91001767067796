.workConMain {
  display: flex;
  margin-bottom: 5%;
  justify-content: center;
}

.worksCon {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin-bottom: 10%;
}

.PartnerprogramHead {
  color: #5e5d5d;
}

.NewBoxCon {
  display: none;
}

.lineCon {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lineCon::before {
  content: "";
  background: #F3F3F3;
  height: 5px;
  position: absolute;
  width: 80%;
}

.BoxCon {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  top: -60px;
  width: 100%;
}

.singleBoxCon {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 15px;
}

.circleShape {
  background-color: #fff;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 4px solid #F3F3F3;
}

.circleShape img {
  width: 100%;
  position: absolute;
  display: block;
}

.BoxCnt {
  width: 72%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

.BoxCnt p {
  font-size: 0.8rem;
  line-height: 0.9rem;
}

@media (max-width: 1024px) {
  .workConMain {
    margin-bottom: 10% !important;
  }
}
@media (max-width: 912px) {
  .circleShape {
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }
  .BoxCon {
    position: absolute;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    top: -40px;
    width: 100%;
  }
  .BoxCnt {
    width: 95%;
  }
}
@media (max-width: 768px) {
  .workConMain {
    width: 100%;
  }
  .worksCon {
    display: none;
  }
  .NewBoxCon {
    display: flex !important;
    width: 100%;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
  }
  .workConMain {
    width: 100%;
  }
  .singleBoxCon {
    width: 60%;
    margin-bottom: 5%;
  }
  .BoxCnt {
    width: 80%;
  }
}
@media (max-width: 415px) {
  .workConMain {
    width: 100%;
  }
  .singleBoxCon {
    width: 90%;
    margin-bottom: 5%;
  }
  .BoxCnt {
    width: 80%;
  }
  .circleShape {
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }
  .NewBoxCon h6 {
    width: 85%;
    padding-bottom: 10%;
    text-align: center;
    font-size: 20px;
    color: #585858;
    font-weight: 600;
  }
}/*# sourceMappingURL=PartnerProgram.css.map */