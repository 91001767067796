.ourProd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.OurProd2 {
  width: 90%;
  flex-wrap: wrap;
  display: flex;
}

.productStart {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4%;
  margin-bottom: 2%;
}

.product_head {
  font-size: 30px;
  font-weight: 700;
  line-height: 23px;
  color: #f5ac38;
}

.product_para {
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  color: #585858;
  padding-top: 2%;
}

.OurProductSub {
  padding-top: 0.6%;
}

.portal_head {
  display: flex;
  align-items: left;
  justify-content: left;
  font-size: 20px;
  font-weight: 510;
  line-height: 21px;
  color: #fff;
}

.portal_des {
  display: flex;
  align-items: left;
  justify-content: left;
  font-size: 12px;
  font-weight: 274;
  line-height: 15px;
  color: #fff;
}

.ourProductCard:hover {
  color: #f5ac38 !important;
  box-shadow: 0px 5px 10px 0px rgb(245, 172, 56) !important;
  cursor: pointer;
}

.cardMedia {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}

.blurCard {
  opacity: 0.7;
  mix-blend-mode: multiply;
  background: linear-gradient(357.69deg, #000000 7.56%, rgba(245, 172, 56, 0) 108.45%, url("../../../assets/images/DummyPortal.svg"));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.productCardBtn {
  background-color: #16469D !important;
  color: #ffffff;
  margin-left: 90%;
  margin-top: -16%;
  font-size: 15px;
  line-height: 23px;
  font-weight: 400;
  padding: 0.7% 1.5%;
  text-transform: none;
}

.productCardBtn:hover {
  background-color: #2458B7 !important;
  color: #ffffff !important;
}

.Cardmain2 {
  width: 100%;
  display: none;
}

.Cardmain2 .card1234 {
  display: flex;
  flex-direction: row !important;
  width: 100%;
}

.CardStart2 {
  position: relative;
  width: 600px;
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  align-items: center;
  height: 300px;
}

.cardMedia2 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: absolute;
  left: 2%;
  bottom: 5%;
}

.lastItemCard {
  position: relative;
}

.TargetCardBtn22 {
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3725490196);
}

.productCardBtn22 {
  text-transform: capitalize;
  padding: 2% 6%;
}

@media (max-width: 768px) {
  .Cardmain2 {
    width: 90%;
  }
  .productStart {
    margin-bottom: 5%;
  }
  .CardStart2 {
    width: 95%;
    height: 22vh;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: fill !important;
    margin-left: 2% !important;
  }
  .Cardmain2 {
    display: block;
  }
  .Cardmain {
    display: none;
  }
  .productStart h4 {
    margin-top: 5%;
  }
  .OurProductSub {
    display: none;
  }
  .cardMedia2 h6 {
    font-size: 15px;
  }
  .cardHeader {
    width: 100%;
    display: flex;
    gap: 144px !important;
    justify-content: space-between;
    margin-bottom: 5%;
    align-items: center;
  }
  .cardHeader > h1 {
    font-size: 20px;
    color: #585858;
    font-weight: 600;
    padding: 10px 0;
  }
  .cardHeader h3 {
    font-size: 15px;
  }
  .productStart h4 {
    display: none;
  }
  .BtnLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .CardIcon {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    padding: 20%;
    background-color: #16469D;
    color: #fff;
  }
  .ourProd {
    margin-bottom: 15%;
  }
}
.Cardmain {
  display: flex;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.Cardmain > a {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 270px;
  width: 100%;
  max-width: calc(25% - 20px);
}

@media screen and (max-width: 768px) {
  .Cardmain2 {
    display: block;
  }
  .Cardmain {
    display: none;
  }
  .Cardmain > a {
    max-width: 100%;
  }
}/*# sourceMappingURL=OurProduct.css.map */