.ProjectDescPageCardsMain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-bottom: 10%;
}

.ProjectDescPageCardsHeadiing {
  display: flex;
  width: 85%;
  margin-bottom: 4%;
  align-content: flex-start !important;
}

.ProjectDescPageCardsHeadiing h4 {
  display: flex;
  font-size: 30px;
  color: #585858;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.ProjectDescPageCardsImgs {
  display: flex;
  width: 85%;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 20px;
  flex-wrap: wrap;
}

.ProjectPageimg {
  padding: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 2px solid rgba(54, 54, 54, 0.199);
}

.ProjectPageimg img {
  margin-bottom: 5% !important;
}

.ProjectPageimg h5 {
  display: flex;
  font-size: 20px;
  color: #585858;
  font-weight: 700;
  margin-bottom: 2%;
  justify-content: center;
}

.ProjectPageimg h6 {
  display: flex;
  font-size: 18px;
  color: #383838;
  font-weight: 500;
  margin-bottom: 5%;
  justify-content: center;
}

@media (max-width: 1380px) {
  .ProjectPageimg {
    width: 300px;
  }
  .ProjectPageimg img {
    width: 250px;
  }
}
@media (max-width: 1110px) {
  .ProjectPageimg {
    width: 250px;
  }
  .ProjectPageimg img {
    width: 200px;
  }
}
@media (max-width: 915px) {
  .ProjectPageimg {
    width: 350px;
  }
  .ProjectPageimg img {
    width: 300px;
  }
}
@media (max-width: 840px) {
  .ProjectDescPageCardsImgs {
    gap: 20px;
  }
}/*# sourceMappingURL=ProjectDescriptionPageCards.css.map */