.cardsize {
  width: 330px;
  box-shadow: none;
}

.backgroundImg {
  display: flex;
  justify-content: left;
  width: 100%;
  height: 410px;
  background: linear-gradient(123.13deg, rgba(67, 67, 67, 0.6) 25.08%, rgba(187, 123, 21, 0.6) 104.94%), url(../../../assets/images/OurProjectsPageBackImg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
}

.bnr_head {
  color: white;
  justify-content: left;
  align-items: center;
  font-size: 2.5rem;
  line-height: 42px;
  font-weight: 700;
  color: #fff;
}

.bnr_para {
  color: white;
  justify-content: left;
  font-size: 1.125rem;
  line-height: 27px;
  font-weight: 400;
  color: #fff;
  padding-top: 10px;
}

.projectcard {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 15px;
  margin-top: 50px;
  box-shadow: none;
}

.project_Head {
  font-size: 20px;
  line-height: 31.38px;
  font-weight: 700;
  color: #1c2752;
  text-align: center;
}

.project_text {
  font-size: 17px;
  line-height: 21.78px;
  font-weight: 400;
  color: #7e7e7e;
  text-align: center;
}

/* ------------------------------------------------------------------------------------------- */
@media screen and (max-width: 768px) {
  .cardsize {
    width: 300px;
    height: auto;
  }
}
.projectcard {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 15px;
  margin-top: 50px;
  box-shadow: none;
}

@media screen and (max-width: 500px) {
  .backgroundImg {
    background-color: #ffe8c5;
    background-image: none;
  }
  .projectcard {
    width: 100%;
  }
  .bnr_txt {
    width: 100%;
    height: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .bnr_head {
    color: #f5ac38;
  }
  .bnr_para {
    color: #656363;
    font-size: 16px;
  }
}/*# sourceMappingURL=OurProjectsPage.css.map */