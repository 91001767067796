* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.Testimonialcarousel {

  width: 100%;
}
.Testimonialbox {
  display: flex;
  flex-direction: column;
  position: relative;
}

.testimonialMessageContainer {
  max-height: 100px; 
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;  
}

.testimonialMessageContainer::-webkit-scrollbar {
  display: none;  
}

.testimonialMessage {
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  text-align: center;
  padding-left: 4%;
  padding-right: 4%;
  word-wrap: break-word;
}

#custom .Testimonialbox {
  text-align: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  position: relative;
    height: 250px; 
    overflow: hidden;


}

.Testimonialbox h6 {
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  text-align: center;
  padding-left: 4%;
  padding-right: 4%;
  text-overflow: ellipsis; 

}

.Testimonialbox h2 {
  width: 100%;
  padding-top: 5%;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  position: absolute;
  bottom: 10%;
  text-overflow: ellipsis; 

}

.Testimonialbox h4 {
  width: 100%;
  font-weight: 510;
  font-size: 10px;
  line-height: 23px;
  text-align: center;
  position: absolute;
  bottom: 2%;
  text-overflow: ellipsis; 

}

.Testimonialhead {
  padding-left: 5%;
}

.Testimonialhead h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 23px;
  color: #585858;
  padding-bottom: 1%;
  margin-top: 5%;
}

.Testimonialhead h6 {
  font-size: 15px;
  line-height: 23px;
  color: #585858;
  padding-bottom: 2%;
}

.Testimonialicons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;


}

/* // ---------------media--------------------------- */
@media(max-width:952px) {
  .Testimonialbox h6 {
    font-size: 12px;
    line-height: 12px;
    
  }

  .Testimonialbox h2 {
    padding-top: 1%;
    font-size: 12px;
    line-height: 12px;
    
  }
  
  .Testimonialbox h4 {
    font-size: 8px;

  }

}

@media(max-width:550px) {
  .Testimonialhead {
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Testimonialhead h2 {
    font-size: 20px;
  }

  .Testimonialhead h6 {
    font-size: 15px;

  }

  #custom .slick-slide>div {
    margin: 0 20px;

  }

  .Testimonialicons .testiicon{
    font-size: 50px;
  }

  .Testimonialbox h6 {
    font-size: 12px;
  }

  .Testimonialbox h2 {
    font-size: 12px;

  }

  .Testimonialbox h4 {
    font-size: 12px;

  }
}

@media(max-width:821px) {
  #custom .slick-slide>div {
    margin: 0 15px;

  }
}

@media(max-width:913px) {
  #custom .slick-slide>div {
    margin: 0 15px;

  }
}

@media(max-width:1025px) {
  #custom .slick-slide>div {
    margin: 0 15px;

  }
}

@media(max-width:1281px) {
  #custom .slick-slide>div {
    margin: 0 25px;

  }
}
@media(max-width:1458px) {
  
  .Testimonialicons .testiicon{
    font-size: 50px;
  }
}

@media(max-width:478px) {
  .Testimonialbox h6 {
    font-size: 15px;
    line-height: 20px;
  }
  
  .Testimonialbox h2 {
    font-size: 15px;
    line-height: 23px;
    
  }
  
  .Testimonialbox h4 {
    line-height: 23px;
    font-size: 15px;

  }
}
@media(max-width:450px) {
  .testiicon{
    margin: 0 !important;
    padding: 0 !important;
    font-size: 30px !important;
  }
  .Testimonialbox h6 {
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 0 !important;
  }
  
  .Testimonialbox h2 {
    font-size: 12px;
    line-height: 18px;
    padding: 0 !important;
    padding-bottom:  0 !important;
    
  }
  
  .Testimonialbox h4 {
    line-height: 12px;
    font-size: 10px;
    padding-top: 0 !important;
    padding-bottom: 2%;

  }
  #custom .slick-slide>div {
    margin: 0 12px;

  }
  
  #custom .slick-slide>div >div{
    height: 180px;

  }


}

@media(max-width:415px) {
  .Testimonialhead h6 {
    font-weight: 500;
    width: 100%;
    text-align: start;
    display: flex;

  }
  .Testimonialhead h2 {
    font-weight: 600;
    font-size: 20px;
    color: #585858;
    text-align: start;
    width: 100%;
    display: flex;

  }
  .Testimonialcarousel{
    margin-bottom: 10%;
  }
}

@media(max-width:395px) {
  .Testimonialbox h6 {
    font-size: 10px;
    line-height: 15px;

  }
  
  .Testimonialbox h2 {
    font-size: 10px;
    line-height: 15px;

    
  }
  
  .Testimonialbox h4 {
    font-size: 8px;
    line-height: 10px;

  }
}