.FAQPageQ {
  width: 100%;
  margin-top: 5%;
}

.FAQContainer2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 5% 0% 3%;
}

.questionBox {
  width: 350px;
  height: 230px;
  margin: 0% 2%;
}
.questionBox h5 {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: rgb(83, 104, 106);
  margin-top: 1%;
}
.questionBox h6 {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: rgb(83, 104, 106);
  margin-top: 2%;
}

.FAQContainer3 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}

.IconContainer {
  border-radius: 50%;
  background-color: rgb(255, 232, 197);
}

.faq-accordion {
  width: 60%;
  margin-left: 5%;
}

.faq-item {
  margin-bottom: 5px;
  background-color: transparent;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 1%;
  border-bottom: 1px solid #F5AC38;
}

.faq-drop {
  font-size: 15px;
  background: #F5AC38;
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
}

.faq-answer {
  color: rgb(88, 88, 88);
  font-size: 12px;
  line-height: 21px;
  font-weight: 400;
  color: #585858;
}

.faq-title {
  color: #585858;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
}

.faq-title span {
  color: #585858;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
}

.faq-content {
  color: rgb(88, 88, 88);
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.faq-show {
  padding-bottom: 10px;
  max-height: 9999;
  font-size: 15px;
  color: rgb(88, 88, 88);
  font-weight: 100;
}

@media (max-width: 700px) {
  .FAQContainer2 {
    display: flex;
    flex-direction: column;
  }
  .FAQContainer3 {
    display: flex;
    flex-direction: column;
  }
}/*# sourceMappingURL=FAQPageQ.css.map */