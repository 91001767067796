.contactUs {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background: #f7faff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-right: 5%;
}

.contactUsForm {
  width: 35%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 6px;
  padding: 3%;
  margin: 3% 0% 3%;
}

.contactUsBtn:hover {
  background-color: #16469D;
}

.bnrHead {
  font-size: 40px;
  font-weight: 700;
  line-height: 42px;
  color: rgb(31, 22, 22);
}

.bnrPara {
  font-size: 400;
  font-weight: 20px;
  line-height: 23px;
  color: #FFFFFF;
}

@media (max-width: 500px) {
  .contactUs {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0%;
  }
  .contactUsForm {
    width: 40%;
  }
  .bnrHead {
    font-size: 40px;
    font-weight: 700;
    line-height: 42px;
    color: rgb(31, 22, 22);
  }
  .contactUsForm {
    width: 35%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #F1F1F1;
    border-radius: 6px;
    padding: 3%;
    margin-top: 5%;
  }
}
@media (max-width: 800px) {
  .contactUs {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0%;
  }
  .contactUsForm {
    width: 60%;
  }
}
@media (max-width: 1280px) {
  .contactUsForm {
    width: 50%;
  }
  .ContactBoxPF {
    width: 40% !important;
  }
}
@media (max-width: 1024px) {
  .BePartnerHead {
    padding-top: 13px;
  }
  .contactUs {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0%;
  }
  .contactUsForm {
    width: 80%;
  }
  .ContactBoxPF {
    width: 80% !important;
  }
}
@media (max-width: 415px) {
  .ContactBoxPF {
    width: 90% !important;
    text-align: start !important;
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start;
    padding-top: 10%;
    margin-bottom: 0 !important;
  }
  .ContactBoxPF h6 {
    width: 100% !important;
  }
  .contactUsForm h5 {
    font-size: 20px;
  }
  .contactUsForm {
    width: 90%;
    margin-top: 5% !important;
    margin-bottom: 15%;
    padding: 5%;
  }
  .contactUsForm label {
    font-size: 12px !important;
  }
  .contactUsBtn {
    font-size: 12px !important;
  }
  .partnerFull {
    display: flex;
    flex-direction: column !important;
    width: 100% !important;
    margin: 2% 0;
  }
  .contactUsBtn {
    padding: 3% 0;
    margin: 5% 0;
  }
  .Partnerfull2 {
    width: 100%;
    margin: 0 !important;
  }
}/*# sourceMappingURL=PartnerForm.css.map */