:root {
  --col: #16469D;
  --bl: #585858;
  --hov: #2458B7;
  --wt: #fff;
}

.FooterMain {
  width: 100%;
  display: flex;
  background-color: #00293a;
  justify-content: center;
  align-items: center;
}

.FooterStart {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.FooterContainer1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
  margin-top: 2%;
}

.FooterContainer2 {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
}

.FooterBox1 {
  display: flex;
  flex-direction: column;
  width: 20%;
}

.FooterBox1 img {
  display: flex;
  width: 100%;
}

.FooterBox2 {
  width: 75%;
  display: flex;
  justify-content: space-around;
}

.FooterBox {
  width: 12%;
  padding-top: 2%;
}

.FooterBox ul li {
  list-style: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--wt);
  padding-bottom: 8%;
}

.FooterBox h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: var(--wt);
  padding-bottom: 8%;
}

.FooterBox ul {
  padding-left: 0 !important;
}

.FooterBox1 h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  margin-top: 4%;
}

.FooterIcon {
  width: 90%;
}

.FooterDownBox1 {
  width: 15%;
  display: flex;
  flex-direction: column;
}

.SocialIcons {
  display: flex;
  width: 60%;
  justify-content: space-between;
}

.FooterDownBox1 h6 {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 4%;
}

.footercopyright {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  text-transform: uppercase;
}

.footertos {
  display: flex;
  width: 35%;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #c8c4c4;
  justify-content: space-between;
  align-items: end;
}

.FooterBoxHide {
  display: none;
}

/* ----------------------------------------------- */
@media (max-width: 1140px) {
  .FooterBox1 {
    width: 22%;
  }
  .FooterBox1 h6 {
    font-size: 12px;
  }
  .FooterBox {
    width: 20%;
  }
  .FooterBox ul li {
    font-size: 12px;
  }
  .FooterBox h4 {
    font-size: 15px;
  }
  .FooterDownBox1 {
    width: 25%;
  }
  .FooterDownBox1 h6 {
    font-size: 12px;
  }
  .footercopyright {
    font-size: 12px;
  }
  .footertos {
    width: 40%;
    font-size: 12px;
  }
}
@media (max-width: 825px) {
  .FooterContainer1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .FooterDownBox1 {
    width: 35%;
  }
  .FooterBox1 {
    width: 34%;
    text-align: center;
    margin-bottom: 2%;
  }
  .FooterBox2 {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .FooterBox {
    width: 17%;
  }
  .FooterIcon {
    text-align: center;
  }
  .FooterIcon img {
    width: 100%;
  }
  .footertos {
    width: 45%;
  }
}
@media (max-width: 679px) {
  .FooterBox2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
  .FooterBox {
    flex: 5 0 150px;
  }
  .FooterBox h4 {
    padding-bottom: 15px;
  }
  .FooterContainer2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footertos {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5%;
  }
  .FooterDownBox1 {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .FooterBox ul li {
    padding-bottom: 15px;
  }
}
@media (max-width: 500px) {
  .FooterBox1 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .FooterIcon {
    width: 40%;
  }
  .FooterIcon img {
    width: 100%;
  }
}
@media (max-width: 416px) {
  .FooterBox2 {
    gap: 20px;
    display: flex;
    box-sizing: border-box;
    margin-top: 5%;
  }
  .FooterBox1 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    margin-top: 5%;
  }
  .FooterBox {
    display: flex;
    flex-direction: column;
    text-align: left;
    box-sizing: border-box !important;
    gap: 1px;
    padding-left: 1%;
  }
  .SocialIcons {
    margin-bottom: 2%;
  }
  .FooterContainer2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .FooterDownBox1 {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .footertos {
    margin-bottom: 4%;
    display: none;
  }
  .FooterBox ul li {
    padding-bottom: 4%;
  }
  .footercopyright {
    margin-bottom: 4%;
  }
  .SocialIcons {
    display: flex;
    width: 50%;
  }
}/*# sourceMappingURL=Footer.css.map */