.findyouranswers {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 5%;
  margin-bottom: 10%;
}

.findyouranswersStart {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.mobileview {
  flex: 1 0 50%;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  gap: 20px;
}

.AnsContact {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  font-weight: 700;
  border: 1px solid #16469D;
  border-radius: 6px;
  background-color: #FDFDFD;
  padding: 5% 5% 5% 5%;
  width: 80%;
  gap: 10px;
}

.AccordianStart {
  flex: 1 0 50%;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  gap: 20px;
}

.accordion {
  display: flex;
  flex-direction: column;
}

.acordRow {
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid #16469D;
  display: flex;
}

.accordhead {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 15px;
  height: 70px;
  font-weight: 500;
  align-items: center;
  cursor: pointer;
}

.accordBody {
  padding: 10px;
  font-size: 15px;
  font-weight: 400;
}

@media screen and (max-width: 900px) {
  .AnsContact {
    width: 100%;
  }
}/*# sourceMappingURL=FAQ.css.map */