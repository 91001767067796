.Firstclass {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.secondMainClass {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.secondMainText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.secondMainText h1 {
  width: 80%;
  font-weight: 274;
  font-size: 15px;
  line-height: 23px;
}

.buttonClass {
  width: 150px;
  height: 36px;
  margin-top: 10%;
  border-radius: 10px;
  background-color: #16469D;
}

.buttonClass:hover {
  background-color: #16469D;
}

@media (max-width: 451px) {
  .secondMainClass {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;
  }
  .secondMainClass img {
    width: 80%;
  }
}/*# sourceMappingURL=AllVerification.css.map */