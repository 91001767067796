.ProjectDescriptionImageInfoMain {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 80px;
}

.ProjectDescriptionStart {
  display: flex;
  width: 85%;
  justify-content: space-between;
  gap: 20px;
  height: 100%;
}

.ProjectDescriptionImageInformation {
  display: flex;
  flex: 1 0 250px;
  justify-content: center;
  align-items: flex-start;
  background: #f6faff !important;
  box-sizing: border-box;
  padding: 30px 20px;
}

.ProjectDescriptionInformation {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.ProjectDescriptionInformation h4 {
  font-size: 20px;
  color: rgb(28, 39, 82);
  font-weight: 700;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.ProjectDescriptionInformation h5 {
  font-size: 18px;
  color: rgb(28, 39, 82);
  font-weight: 700;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding-bottom: 1%;
}

.ProjectDescriptionInformation h6 {
  font-size: 18px;
  color: rgba(49, 48, 48, 0.674);
  font-weight: 400;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.ProjectDescriptionInformation .border {
  width: 100%;
  position: relative;
}

.BoxBorderLine {
  width: 20%;
  height: 3px;
  background-color: #16469D;
}

.ProjectDescriptionBGMain {
  display: flex;
  width: 70%;
}

.ProjectDescriptionBG {
  width: 100%;
}

.silderImgProjectDescSlide {
  width: 100%;
  height: 100%;
  max-height: 80vh;
}

.silderImgProjectDesc {
  width: 100%;
  height: 100%;
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.BoxLine {
  padding: 5% 0;
  border-bottom: 0.5px solid #585858;
}

@media screen and (max-width: 1000px) {
  .ProjectDescriptionImageInfoMain {
    padding: 0 20px;
  }
  .ProjectDescriptionStart {
    flex-direction: column;
    width: 100%;
  }
  .ProjectDescriptionImageInformation {
    width: 100%;
  }
  .ProjectDescriptionBGMain {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .ProjectDescriptionInformation h5 {
    font-size: 25px !important;
  }
  .ProjectDescriptionInformation h6 {
    font-size: 20px !important;
  }
  .BoxLine h4 {
    font-size: 30px !important;
  }
}
@media (max-width: 540px) {
  .ProjectDescriptionInformation h5 {
    font-size: 15px !important;
  }
  .ProjectDescriptionInformation h6 {
    font-size: 12px !important;
  }
  .BoxLine h4 {
    font-size: 20px !important;
  }
}/*# sourceMappingURL=ProjectDescriptionImageInfo.css.map */