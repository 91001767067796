.ProjectDescPageMainInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(https://res.cloudinary.com/dxluokypg/image/upload/v1679738257/Indian_Tricolor_Flag_Png_Free_Download_-_1280x915_1_akzpik.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.ProjectDescPageMainContent {
  width: 85%;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
}

.ProjectDescPageMainContent > div > * {
  font-size: 18px;
  color: #5e5e5e;
  font-weight: 400;
  line-height: 1.7;
}

.ProjectDescPageMainContent > div > ul, .ProjectDescPageMainContent > div > ol {
  padding: 0 20px;
}

@media (max-width: 768px) {
  .ProjectDescPageMainInfo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
  }
  .ProjectDescPageMainContent {
    display: flex;
    width: 85%;
  }
}/*# sourceMappingURL=ProjectDescriptionPageMain.css.map */