.MainFaq {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
  background-color: #ffffff;
}

.SecondFaqTab {
  display: flex;
  flex-direction: column;
  width: 85%;
  box-sizing: border-box;
}

.TabStart1 {
  display: flex;
  width: 100%;
  margin-bottom: 1.5%;
  padding-left: 1%;
}

.TabStart1 h5 {
  color: #585858;
  font-weight: 700;
  font-size: 22px;
}

.TabStart {
  display: flex;
  width: 100%;
}

.TabBox1 {
  width: 20%;
  text-align: left;
}

.TabBox2 {
  width: 100%;
}

.FaqPanelBox {
  width: 100%;
  box-shadow: none;
}

.TabHead {
  display: flex;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  align-items: self-start;
  text-transform: capitalize;
  text-align: left;
}

.FaqPanel {
  display: flex;
  background-color: #ffffff;
  border-radius: 3px;
  width: 100% !important;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
  padding: 2%;
}

.FaqQue {
  font-size: 15px;
  line-height: 23px;
  font-weight: 500;
  color: #585858;
}

.FaqAns {
  font-size: 15px;
  line-height: 23px;
  font-weight: 400;
  color: #585858;
}

.css-19kzrtu {
  padding: 0 2% 0 2%;
}

.scrollable-container {
  height: 300px;
  overflow-y: scroll;
}

.scrollable-container::-webkit-scrollbar {
  width: 8px;
}

.scrollable-container::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #D9D9D9;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #16469D;
  border-radius: 5px;
}

.TabBoxHide {
  display: none;
}

/* ------------media------------------ */
@media (max-width: 1200px) {
  .PanelBox {
    width: 21%;
  }
  .PanelBox2 {
    width: 21%;
    justify-content: center;
  }
}
@media (max-width: 1160px) {
  .PanelBox {
    width: 21%;
  }
  .PanelBox2 {
    justify-content: center;
    align-items: center;
    width: 21%;
  }
}
@media (max-width: 769px) {
  .TabStart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    width: 100%;
  }
  .TabLabel {
    align-items: normal;
    text-align: start;
  }
  .TabStart {
    width: 100% !important;
  }
  .TabBox1 {
    margin-bottom: 5%;
    width: 100% !important;
  }
}
@media (max-width: 602px) {
  .TabsMain {
    width: 40%;
  }
}
@media (max-width: 445px) {
  .PanelBox {
    width: 23%;
  }
  .PanelBox2 {
    width: 23%;
  }
}
@media (max-width: 415px) {
  .TabStart1 h5 {
    font-size: 15px;
  }
  .TabHead {
    width: 100%;
    font-size: 12px;
  }
  .TabHeadHide {
    border-radius: 10px;
    border: 1px solid black !important;
  }
  .TabBoxHide {
    display: block;
  }
  .TabBox1 {
    display: none;
  }
  .scrollable-container {
    border-left: 1px solid #fff !important;
  }
}
@media (max-width: 335px) {
  .TabsMain {
    width: 60%;
  }
  .PanelBox h4 {
    line-height: normal;
  }
}/*# sourceMappingURL=FaqSection.css.map */