.backgroundImg {
  display: flex;
  justify-content: left;
  width: 100%;
  height: 40vh !important;
  box-sizing: border-box;
  background-image: url("https://res.cloudinary.com/dxluokypg/image/upload/v1683263932/our-projects-_lanend.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bgImgContainer {
  display: flex;
  flex-direction: column;
  width: 77%;
}

.bgImgContainer h5 {
  font-size: 40px;
  line-height: 42px;
  font-weight: 700;
  color: #fff;
}

.bgImgContainer h6 {
  align-items: center;
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  color: #FFFFFF;
  padding-top: 10px;
}

.bgImgContainerStart {
  display: none;
}

@media (max-width: 1024px) {
  .bgImgContainer h5 {
    font-size: 35px;
  }
  .bgImgContainer h6 {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (max-width: 768px) {
  .bgImgContainer h5 {
    font-size: 25px;
    line-height: 20px;
  }
  .bgImgContainer h6 {
    font-size: 15px;
  }
}
@media (max-width: 540px) {
  .bgImgContainer {
    width: 90%;
  }
  .bgImgContainer h5 {
    font-size: 20px;
  }
  .bgImgContainer h6 {
    font-size: 12px;
    line-height: 18px;
  }
}
@media (max-width: 415px) {
  .bgImgContainer h5 {
    font-size: 20px;
  }
  .bgImgContainer h6 {
    font-size: 12px;
    line-height: 18px;
  }
  .bgImgContainer {
    display: none;
  }
  .bgImgContainerStart {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .ContainerStartPRoject {
    text-align: start;
    display: flex;
    flex-direction: column;
    width: 95%;
  }
  .bgImgContainerStart h5 {
    font-size: 20px;
    color: #fff;
    padding-bottom: 4%;
  }
  .bgImgContainerStart h6 {
    width: 100%;
    font-size: 12px;
    color: #fff;
  }
  .backgroundImg {
    height: 25vh !important;
  }
}/*# sourceMappingURL=OurProjectsPageBnr.css.map */